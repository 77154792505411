import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { configurationSettings } from "./config";

const APP_DOMAIN = window.location.hostname;
const isLocal = APP_DOMAIN === "localhost";
const connectionString =
  configurationSettings.Domains[APP_DOMAIN].AppInsightsConnectionString || "";

const initAppInsights = (): void => {
  if (connectionString && !isLocal) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString,
        disableTelemetry: isLocal,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
  }
};

export default initAppInsights;
