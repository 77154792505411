import {
  createRootStore,
  setConfigurationSettingsData,
} from "skipton-features";
import { configurationSettings } from "../config";

const rootStore = createRootStore();
const initRootStore = async (): Promise<void> => {
  const APP_DOMAIN = window.location.hostname;

  rootStore.dispatch(
    setConfigurationSettingsData({
      data: configurationSettings,
      domain: APP_DOMAIN,
    })
  );
};

initRootStore();

export type RootDispatch = typeof rootStore.dispatch;
export const useRootDispatch = (): RootDispatch => rootStore.dispatch;

export default rootStore;
