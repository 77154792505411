import { ConfigurationSettingsApi } from "skipton-common";

export const configurationSettings: ConfigurationSettingsApi.ConfigurationSettings =
  {
    Domains: {
      localhost: {
        AdobeAnalyticsTrackingJS: "",
        OneTrustJS: "",
        OneTrustID: "",
        APIUri:
          "https://skipton-development2.qa.focus-internal.co.uk/ski-api/cffwebapi",
        CFFVideoConfig: "CFFVideoConfigDev",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString: "",
      },
      "griffin-cff-dev.on-saturn.com": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-26335bfef55f-development.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed-test",
        APIUri:
          "https://skipton-development2.qa.focus-internal.co.uk/ski-api/cffwebapi",
        CFFVideoConfig: "CFFVideoConfigDev",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6f3ab9da-8bf5-4abb-a62a-6a8a844215f0;IngestionEndpoint=https://ukwest-0.in.applicationinsights.azure.com/;LiveEndpoint=https://ukwest.livediagnostics.monitor.azure.com/",
      },
      "griffin-cff-preview.on-saturn.com": {
        AdobeAnalyticsTrackingJS: "",
        OneTrustJS: "",
        OneTrustID: "",
        APIUri:
          "https://skipton-preview-2.focus-solutions.co.uk/preview-2/cffwebapi",
        CFFVideoConfig: "CFFVideoConfigDev",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6f3ab9da-8bf5-4abb-a62a-6a8a844215f0;IngestionEndpoint=https://ukwest-0.in.applicationinsights.azure.com/;LiveEndpoint=https://ukwest.livediagnostics.monitor.azure.com/",
      },
      "griffin-cff-sit.on-saturn.com": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-26335bfef55f-development.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed-test",
        APIUri:
          "https://skipton-integration2.qa.focus-internal.co.uk/ski-api/cffwebapi",
        CFFVideoConfig: "CFFVideoConfigDev",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6f3ab9da-8bf5-4abb-a62a-6a8a844215f0;IngestionEndpoint=https://ukwest-0.in.applicationinsights.azure.com/;LiveEndpoint=https://ukwest.livediagnostics.monitor.azure.com/",
      },
      "griffin-cff-uat.on-saturn.com": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-26335bfef55f-development.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed-test",
        APIUri: "https://skipton-staging.focus-solutions.co.uk/cffwebapi",
        CFFVideoConfig: "CFFVideoConfig",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6f3ab9da-8bf5-4abb-a62a-6a8a844215f0;IngestionEndpoint=https://ukwest-0.in.applicationinsights.azure.com/;LiveEndpoint=https://ukwest.livediagnostics.monitor.azure.com/",
      },
      "griffin-cff-preprod.on-saturn.com": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-628cad9aa0c5-staging.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed-test",
        APIUri:
          "https://skipton-pre-production.focus-solutions.co.uk/cffwebapi",
        CFFVideoConfig: "CFFVideoConfig",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6ecc12a6-5da2-47c9-a036-1505a04df44d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
      },
      "griffin-cff-training.on-saturn.com": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-628cad9aa0c5-staging.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed-test",
        APIUri: "https://skipton-training.focus-solutions.co.uk/cffwebapi",
        CFFVideoConfig: "CFFVideoConfig",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6ecc12a6-5da2-47c9-a036-1505a04df44d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
      },
      "griffin-cff-prod-refresh.on-saturn.com": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-fe6cb57eebcd.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed",
        APIUri: "https://skipton-refresh.focus-solutions.co.uk/cffwebapi",
        CFFVideoConfig: "CFFVideoConfig",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6ecc12a6-5da2-47c9-a036-1505a04df44d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
      },
      "griffin-cff-uat-refresh.on-saturn.com": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-26335bfef55f-development.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed-test",
        APIUri:
          "https://skipton-staging-refresh.focus-solutions.co.uk/cffwebapi",
        CFFVideoConfig: "CFFVideoConfig",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6f3ab9da-8bf5-4abb-a62a-6a8a844215f0;IngestionEndpoint=https://ukwest-0.in.applicationinsights.azure.com/;LiveEndpoint=https://ukwest.livediagnostics.monitor.azure.com/",
      },
      "griffin-cff-preprod-refresh.on-saturn.com": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-628cad9aa0c5-staging.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed-test",
        APIUri:
          "https://skipton-pre-production-refresh.focus-solutions.co.uk/cffwebapi",
        CFFVideoConfig: "CFFVideoConfig",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6ecc12a6-5da2-47c9-a036-1505a04df44d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
      },
      "griffin-cff-training-refresh.on-saturn.com": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-628cad9aa0c5-staging.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed-test",
        APIUri:
          "https://skipton-training-refresh.focus-solutions.co.uk/cpwebapi",
        CFFVideoConfig: "CFFVideoConfig",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6ecc12a6-5da2-47c9-a036-1505a04df44d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
      },
      "cff.skiptonfa.co.uk": {
        AdobeAnalyticsTrackingJS:
          "https://assets.adobedtm.com/fe7b460b4908/bb185d632b2a/launch-fe6cb57eebcd.min.js",
        OneTrustJS:
          "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
        OneTrustID: "33557386-8b9c-4f12-b178-d696aae365ed",
        APIUri: "https://secure.skiptonfa.co.uk/cffwebapi",
        CFFVideoConfig: "CFFVideoConfig",
        fileSizeLimitBytes: 10506240,
        fileSizeLimitDisplayName: "10MB",
        allowedFileExtensions: [".docx", ".jpeg", ".jpg", ".pdf"],
        AppInsightsConnectionString:
          "InstrumentationKey=6ecc12a6-5da2-47c9-a036-1505a04df44d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
      },
    },
  };
