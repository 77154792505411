import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import rootStore, { useRootDispatch } from "./store/store";
import initAppInsights from "./app-insights";
import "../web.config";
import "./favicon-imports";
import "../staticwebapp.config.json";

initAppInsights();

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((application) =>
  registerApplication({
    ...application,
    customProps: {
      rootStore,
      useRootDispatch,
    },
  })
);
layoutEngine.activate();
start();
